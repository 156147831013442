<template>
  <div class="container">
    <div class="content">
      <img
        style="display: block; margin: 0 auto; width: 230px; margin-top: 10%"
        src="../../assets/newLogo.png"
      />
      <div class="title">Please enter your entry's ID</div>
      <div class="inputContainer">
        <el-input v-model="code" placeholder="Case Sensitive"></el-input>
        <div class="btn" @click="search">Search</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntryByCode } from '../../api/index'
import '../../assets/common/font.css'
export default {
  data () {
    return {
      code: ''
    }
  },
  methods: {
    search () {
      getEntryByCode(this.code).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data) {
            this.$router.push({
              path: '/detail',
              query: {
                id: res.data.data._id.$id
              }
            })
          } else {
            this.$message.error('No matching entry was found.')
          }
        } else {
          this.$message.error('No matching entry was found.')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  background: url('../../assets/back.png');
  background-size: 100% 100%;
  background-position: 0 0;
  font-family: "DDINAlternateRegular";

  .content {
    width: 63%;
    margin: 0 auto;
    height: 100%;
    background: #fff;
    overflow: hidden;
    .title {
      text-align: center;
      color: #0e4890;
      font-size: 20px;
      font-weight: bold;
      margin-top: 40px;
    }
    .inputContainer {
      width: 68%;
      margin: 50px auto;
      display: flex;
      align-items: center;
      ::v-deep .el-input__inner {
        height: 44px;
        border: 2px solid #0e4890;
        border-radius: 8px 0px 0px 8px;
      }
      .btn {
        width: 186px;
        height: 42px;
        line-height: 40px;
        background: #0e4890;
        border-radius: 0px 8px 8px 0px;
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        border: 2px solid #0e4890;
        cursor: pointer;
      }
    }
  }
}
</style>
